// Override default variables
$primary: #2d4cc8;
$teal: #077b99;
$pink: #f2dede;
$red: #c94d1c;

// Additional Gregor theme
$top-nav-bg: #e4e4dd;
$top-nav-box-shadow: #b9ad8c;
$body-bg: #f4f4ef;

// Alert colors
$mint-green: #d6e9c6;
$dark-pink: #eed3d7;

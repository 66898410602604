@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import '../../node_modules/bootstrap/scss/bootstrap';
@import 'colors';
@import 'fonts';
@import 'brand';

body {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.navbar {
    padding-top: 0;
    padding-bottom: 0;

    .navbar-brand {
        width: 16.0em;

        .gregor-consortium-logo {
            width: auto;
            max-height: 80px;
            min-width: 320px;
        }

        .nav-app-name {
            background: #dc5431;
            border: 3px solid #ffffff;
            border-radius: 2rem;
            color: #fff;
            font-size: 1.0rem;
            line-height: 1.6;
            padding: 4px 16px;
            text-decoration: none;
            transition: all 0.4s ease-in-out;
            width: fit-content;

            &:hover {
                border: 3px solid #222;
            }
        }
    }
}

.page-title {
    .alert {
        padding-top: 0.85rem;
        padding-bottom: 0.85rem;

        .btn-close {
            padding-top: 1.15rem;
            padding-bottom: 1.15rem;
        }
    }

    .page-title-container {
        .badge-beta {
            font-size: 0.85rem;
            margin-left: 0.5rem;
        }
    }
}

.search-results-container {
    .search-results-display-options-sidebar {
        &.show {
            display: block;
        }

        &.hidden {
            display: none;
        }

        .search-results-display-options-sidebar-panel {

            .column-display-option,
            .filter-select-option,
            .type-select-option {
                font-size: 1.0rem;

                input {
                    margin-right: 0.35em;
                }
            }
        
            .column-display-option:not(:last-child),
            .filter-select-option:not(:last-child),
            .type-select-option:not(:last-child) {
                margin-bottom: 0.25em;
            }
        }

        .search-results-display-options-sidebar-panel:last-child {
            margin-bottom: 1.0em;
        }
    }
}

.search-data-table {
    margin-top: 2.5em;

    .pagination-container {
        .records-per-page-select {
            .records-select-label {
                white-space: nowrap;
            }

            .records-select {
                margin-left: 0.5rem;
            }
        }

        .display-options-sidebar-control {
            border-right: solid 1px #ccc;
            margin-right: 1em;
            padding-right: 1em;
        }

        .pagination {
            margin-bottom: 0;
        }
    }
}

.spinner-container {
    .spinner-border {
        width: 5rem;
        height: 5rem;
        opacity: 0.5;
    }
}

.about-section {
    margin-top: 3em;
    margin-bottom: 3em;
}

@media screen and (max-width: 1350px) {
    .search-results-container {
        .search-results-display-options-sidebar-panel.filter-options {
            .filter-select-option:last-child {
                label {
                    display: inline;
                    word-break: break-all;
                    white-space: normal;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .variant-search-ui-container {
        .row {
            --bs-gutter-x: 0 !important;
            width: 100% !important;
        }

        .search-submit-container {
            margin-top: 1.5em !important;
        }
    }
}

@media screen and (max-width: 490px) {
    .navbar {
        .navbar-brand {
            flex-direction: column;
            justify-content: center !important;
            margin-right: 0 !important;
            width: 100%;

            .nav-app-name {
                margin-top: 0.5em;
            }
        }
    }
}
// project specific CSS goes here

// import override bootstrap variables
@import 'brand_custom_bootstrap_vars';

// Custom Gregor theme
h1, h2, h3, h4, h5, h6 {
  color: $dark;
}

.col {
  padding: 1rem;
}

a {
  color: $teal;
  &:hover, &:focus {
    background-color: $light;
    color: $teal;
  }
}
main {
  background-color: $body-bg;
}
#top-nav {
  margin: 0;
  padding: .2rem;
  background: $top-nav-bg;
  box-shadow: inset 0 -2px 2px $top-nav-box-shadow;
  font-size: .8em;

  .nav-link {
    font-weight: bold;
  }

  a:hover,
  a:focus {
  background-color: $light;
  color: unset;
  }
}

#main-nav {
  background-color: $white;
}

a.navbar-brand:hover {
  background-color: unset;
}

.nav-link {
  color: $dark;
}

.navbar-light {
  .navbar-nav {
    .nav-link {
      &:hover,
      &:focus {
        background-color: $dark;
        color: $white;
      }
    }
  }
}

li.nav-item a:hover,
li.nav-item a:focus {
  background-color: $dark;
  color: $white;
}

.btn-primary {
  background: $primary;
  color: $white;
  border-color: unset;

  &:hover, &:focus {
    background-color: $primary !important;
    border-color: $primary;
  }
}

.footer {
  border-top: 4px solid #fff;
  background: #e4dcd3 url(../src/assets/footer-bg.png);

  .footer-bottom {
    position: relative;
    padding: 1rem 0;

    p {
      margin: 1rem 0 1rem 0 !important;

      a {
        color: #dc5431;
        background-color: transparent;
        text-decoration: none;
        transition: color 0.4s ease;

        &:hover {
          color: #176738;
        }
      }
    }
  }
}

////////////////////////////////
		//Alerts//
////////////////////////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}

/* allauth */

.socialaccount_providers {
  list-style-type: none;
}
